body {
  margin: 0;
  text-wrap: pretty;
  font-family: "Sectra", "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;

  p,
  h1,
  h2 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @supports (hanging-punctuation: first) {
    hanging-punctuation: first;
  }
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: "Sectra";
  font-style: normal;
  src: url("./assets/fonts/GTSectra-Book.ttf");
}

@font-face {
  font-family: "Sectra";
  font-style: italic;
  src: url("./assets/fonts/GTSectra-BookItalic.ttf");
}
